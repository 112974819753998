<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="doctorName">
        <el-input placeholder="医生姓名" v-model="searchController.formModel.doctorName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="phoneNumber">
        <el-input placeholder="手机号" v-model="searchController.formModel.phoneNumber" clearable></el-input>
      </el-form-item>
      <el-form-item prop="doctorPsptNo">
        <el-input placeholder="身份证号" v-model="searchController.formModel.doctorPsptNo" clearable></el-input>
      </el-form-item>
      <el-form-item prop="hospitalName">
        <el-input placeholder="医疗机构" v-model="searchController.formModel.hospitalName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="departName">
        <el-input placeholder="科室" v-model="searchController.formModel.departName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="doctorTitleCode">
        <FetchSelect
          v-model="searchController.formModel.doctorTitleCode"
          placeholder="职称"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'DOCTOR_TITLE' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
      <el-form-item prop="status">
        <FetchSelect
          v-model="searchController.formModel.status"
          placeholder="审核状态"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'DOCTOR_REGISTER_STATUS' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
    </template>
    <template #controll></template>
  </SearchForm>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import needKeepAliveMixin from '@/mixins/needKeepAliveMixin'
import { actionConfirm } from '@/utils/confirm'
import request from '@/utils/request'
import { getIsProd } from '@/utils/utils'
import useRequestData from '@/hooks/useRequestData'

const componentName = '/web/system/doctor/doctorregistermgr'

export default {
  name: componentName, // 需要keepalive的组件 一定要有name，用来把name存在vuex中
  setup() {
    const router = useRouter()

    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        doctorName: '',
        phoneNumber: '',
        doctorPsptNo: '',
        hospitalName: '',
        departName: '',
        doctorTitleCode: '',
        status: '',
      },
    })

    // table
    const columns = [
      { label: '医生姓名', prop: 'doctorName', width: 130 },
      { label: '手机号', prop: 'phoneNumber', width: 130 },
      { label: '身份证号', prop: 'doctorPsptNo' },
      { label: '医疗机构', prop: 'hospitalName', width: 210 },
      { label: '科室', prop: 'departName' },
      { label: '职称', prop: 'doctorTitleName', width: 160 },
      { label: '状态', prop: 'statusName', width: 90 },
      { label: '创建时间', prop: 'createDateStr' },
      {
        label: '操作',
        prop: 'actions',
        width: 90,
        // fixed: 'right',
        render: (_, record) => {
          return (
            <div>
              <CustomLink onClick={() => handleDetail(record)}>{record.status == '10' ? '审核' : '详情'}</CustomLink>
            </div>
          )
        },
      },
    ]

    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const postData = {
          page,
          rows: pageSize,
          ...searchController.formModel,
        }
        return request({
          url: '/web/system/doctorregister/getDoctorRegisterList',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
      defaultPageSize: 50,
    })

    function handleDetail(record) {
      router.push(`/web/system/doctor/doctorregistermgr/detail?staffCode=${record.staffCode}`)
    }

    return {
      searchController,
      tableController,
    }
  },
  ...needKeepAliveMixin,
  components: { SearchForm, ProTable, FetchSelect, CustomLink },
}
</script>

<style lang="less">
@import url('./index_global.less');
</style>
